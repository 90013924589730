function checkAnchorTagUrl() {
    const url = location.href;
    const splittedUrl = url.split('?');;
    if (splittedUrl.length === 2) {
        const element = document.getElementById(splittedUrl[1]);
        if (element != undefined) {
            const elementPosY = element.getBoundingClientRect().top;
            setTimeout(function() {
                window.scrollTo({
                    top: elementPosY - 200,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 200);
        }
    }
}

$(document).ready(function() {

    // Partner Slider
    if ($('.partner-slider').length) {
        $('.partner-slider').slick({
            arrows: false,
            cssEase: 'linear',
            pauseOnFocus: false,
            pauseOnHover: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            infinite: true,
            speed: 3500,
            autoplaySpeed: 0,
            mobileFirst:true,
            draggable: false,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    }

    // Check anchor tag (replaced with "?") in the URL to scroll to the element
    checkAnchorTagUrl();

});
